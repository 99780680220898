/* Header */
.ui.inverted.menu {
  background: white!important;
  padding: 4px;
}

.ui.menu img {
  width: 130px!important;
}

.top_description {
  margin: 68px 0 0 0;
  color: #333!important;
}

.icon.top-description-icon {
  color: gray;
}

/* Form */
.Search__Form {
  margin: 16px 0 50px 0;
}

.icon.search-form-icon {
  color: #57abff;
}

.Search__Button {
  text-align: center;
}

.Search__Button__Design {
  display: inline-block;
  max-width: 210px;
  text-align: left;
  border: 0;
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 16px 16px;
  border-radius: 4px;
  transition: .4s;
  background: #fff;
  cursor: pointer;
  background-color: #57abff;
}

.Search__Button__Design:active {
  background-color: #74CBCC;
  border-color: #74CBCC;
  color: #FFF;
}

.field label {
  color: #333!important;
}

.field span {
  display: block;
  color: gray;
  font-size: 13px;
  margin: 0 0 8px 4px;;
}

.segment {
  margin: 20px;
}

.search_checkbox {
  padding: 0 16px 8px 0;
}

/*Result*/
.item.result-item {
  margin: 16px 0!important;
  padding: 16px!important;
  background-color: white!important;
  border: 0px!important;
}

.ui.items:last-child {
  margin: 20px 0;
  padding-bottom: 32px;
}

.ui.items>.item a {
  color: #F3F1DB;
}

.icon.result-icon {
  color: #57abff;
}

.description p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* 制限したい行数が3の場合 */
  overflow: hidden;

  font-size: 13px;
  color: #333;
}

.cinema {
  font-size: 20px;
  color: black;
  line-height: 24px;
}

.result-key-info {
  margin-top: 8px!important;
}

.result-prefecture {
  font-size: 16px;
  color: gray;
  margin-left: 8px;
}

.result-value {
  font-size: 18px!important;
  color: #333;
  margin-top: 4px;
}

.item-buttons {
  text-align: center;
}

.item-button {
  display: inline-block;
  padding: 10px 24px 10px 32px;
  margin: 16px 0 4px 0;
  background: #F1647A;
  border: #F1647A;
  border-radius: 4px;
  font-weight: bold;
}

.item-button-area {
  display: flex;
}

.item-link {
  margin: 0 auto;
}

/* loading */
.loading {
  display: table;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.8;
  color: black;
}

.loading p {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  }

.loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}

/* footer */
.footer-link {
  padding: 2px 0 14px 0!important;
}
